.button-area {
  margin: 2em;
  padding: 0 2em;
  display: grid;
  gap: 1em;
  justify-items: center;
}

.download-button {
  width: 60%;
  max-width: 25rem;
  font-size: 18px;
  font-weight: bold;
  padding: 1em 0;
  border-radius: 3px;
  background-color: #195190;
  color: #c7d3d4;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: none;
}

.download-button:hover {
  opacity: .9;
  color: #fcf6f5;
}

.download-button:active {
  background-color: #123a68;
  transform: scale(0.99);
  color: #fcf6f5;
}

.download-button:hover:before {
  transform: translateY(5px);
}

.download-button:after {
  content: "";
  display: inline-block;
  margin-left: .5em;
  width: 0;
  height: 0;
  border-top: 8px solid #c7d3d4;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}

download-button:hover:after {
  border-top: 8px solid #fcf6f5;
}

.button-area {
  position: relative;
}

.button-area.disabled {
  pointer-events: none; /* Disable user clicks */
}

.button-area.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9999; /* Ensure the overlay is on top of other elements */
}
