* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fcf6f5;
}

.pages {
  color: #195190;
  text-align: center;
  margin-top: 2em;
}

/* html,body{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}
body{
  position:relative;
} */

html,
body {
  height: 100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 0rem);
}

.pages {
  flex: 1;
  overflow: auto;
  /* padding-bottom: 50px; Adjust the value to match the height of your footer */
}