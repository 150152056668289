.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.x-button-modal {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fcf6f5;
  cursor: pointer;
  background-color: #195190;
  border: none;
}

.x-button-modal:hover {
  opacity: .9;
}

.x-button-modal:active {
  transform: scale(0.99);
  color: #b9c3c4;
}

.modal-header {
  background-color: #195190;
  color: #fcf6f5;
  padding: 2em 3em;  
}

.modal-form {
  padding: 1em;
  background-color: #fff;
}

.form-group {
  display: grid;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* width: 100%; */
  margin-bottom: 10px;
}

.form-group > label {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-group > input {
  font-size: 16px;
  padding: 5px;
  margin-bottom: 1em;
  border-radius: 5px;
  border-style: hidden;
  outline: none;
  border: solid 1px #111;
  box-shadow: 1px 2px 0 gray;
  transition: all 0.2s ease-in-out;
}

.form-group > input:focus {
  box-shadow: 0px 1px 0 gray;
  transform: scale(0.99);
}

/* Hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin: 0 0 1em 0;
}

.modal-buttons button {
  padding: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.modal-buttons button:first-of-type {
  background-color: #c7d3d4;
  width: 7em;
}

.modal-buttons button:last-of-type {
  background-color: #195190;
  color: #fff;
  width: 7em;
}

.modal-buttons button:hover {
  opacity: .9;
}

.modal-buttons button:first-of-type:active {
  transform: scale(0.99);
  background-color: #b9c3c4;
}

.modal-buttons button:last-of-type:active {
transform: scale(0.99);
background-color: #123a68;
}

.invalid {
  color: #C70039;
}

.invalid + input {
  border: solid 1px #C70039;
  box-shadow: 1px 2px 0 #a00430;
}

.invalid + input:focus {
  box-shadow: 0px 1px 0 #a00430;
}

label.invalid {
  color: #C70039;
}
