
.floating-button {
   position: fixed; 
   /* width: 100%; */
   right: 0.5em;
   bottom: 3em;
   /* height: 20px; */
   /* font-size: 2rem; */
   z-index: 1;
   cursor: pointer;
  background-color: #fcf6f5;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  transition: all ease-in-out 0.3s;
}

.floating-button:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.floating-button:active {
  opacity: 1;
  transform: scale(0.95);
}

@media screen and (max-width: 480px) {
  .floating-button {
    /* bottom: 7em; */
  }
}