.contact-form {
  width: 80%;
  margin: auto;
  border-radius: 5px;
  max-width: 55vw;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 1rem;
}

.submit-button {
  color: #c7d3d4;
  transition: all 0.5s ease;
  background-color: #195190;
  font-weight: bold;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  width: 30%;
  display: block;
  font-size: larger;
  padding: 1em 0em 1em 0em;
}

.submit-button:hover {
  color: #fcf6f5;
  transition: all 0.5s ease;
}

.submit-button:active {
  opacity: 0.5;
}

.contact-form-group {
  display: grid;
  margin-bottom: 10px;
}

.contact-form-group > label {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-form-group > input, .contact-form-group > textarea {
  font-size: 16px;
  padding: 5px;
  margin-bottom: 1em;
  border-radius: 5px;
  border-style: hidden;
  outline: none;
  border: solid 1px #111;
  box-shadow: 1px 2px 0 gray;
  transition: all 0.2s ease-in-out;
}

.contact-form-group > textarea {
  height: 7rem;
}

.contact-form-group > input:focus, .contact-form-group > textarea:focus {
  box-shadow: 0px 1px 0 gray;
  transform: scale(0.99);
}

.captcha {
  justify-self: center;
  margin-bottom: 1em;
}

.help-text {
  color: #C70039;
  margin-bottom: 1em;
}

@media screen and (max-width: 560px) {
  .contact-form {
    width: 90%;
    max-width: none;
    padding-bottom: 7rem;
  }
}