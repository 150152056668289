.main-wrapper {
  margin-bottom: 4rem;
}

table {
  border-collapse: collapse;
  width: 90%;
  max-width: 800px;
  margin: auto;
  margin-bottom: 1em;
  table-layout: fixed;
}

/* Hidden tr with 3 tds, to define column widths as percentages */
thead > tr > td:nth-child(3),
thead > tr > td:nth-child(2) {
  width: 18%;
  visibility: hidden;
}

thead > tr > td:nth-child(1) {
  width: 50%;
  visibility: hidden;
}

th {
  padding: 2em 2em 1em 2em;
  border-bottom: 1px solid #195190;
}

th.section-label {
  color: #fcf6f5;
  background-color: #195190;
  font-size: larger;
  padding: 1em 0em 1em 0em;
}

.course-cell {
  position: relative;
}

.course-input {
  font-size: medium;
  font-weight: bold;
}

.course-text-container {
  min-width: 12em;
  display: flex;
  height: 3.5em;
  align-items: center;
}

.text-course {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1em;
}

td > input {
  width: 100%;
  height: 100%;
  text-align: center;
  direction: ltr;
  border: none;
  cursor: pointer;
  padding: 1em;
  box-sizing: border-box;
  color: #195190;
  background-color: #fcf6f5;
}

.course-row:hover td, .course-row:hover .course-input {
  background-color: #fff;
  /* filter: brightness(110%); */
}

td >input:hover {
  font-weight: bold;
}

td > input:focus {
  cursor: unset;
  background-color: #fff;
  color: #111;
  font-weight: bold;
  outline: none;
  border-bottom: 1px solid #195190;
}

th.section-label button {
  color: #c7d3d4;
  /* text-decoration: none; */
  transition: all 0.5s ease;
  background-color: #195190;
  font-size: large;
  font-weight: bold;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  width: 100%;
  display: block;
}

th.section-label button:hover {
  color: #fcf6f5;
  transition: all 0.5s ease;
}

th.section-label button:active {
  opacity: 0.5;
}


@media screen and (max-width: 480px) {
  .main-wrapper {
    margin-bottom: 8rem;
  }

  .responsive-table th, td {
    display: block;
    width: 100%;
    text-align: left;
  }
  
  .responsive-table th.column-header {
    display: none;
    visibility: hidden;
  }

  .responsive-table th.section-label {
    text-align: center;
    padding: 1em 0em 1em 0em;
  }

  .responsive-table td::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    width: 40%;
    position: absolute;
    top: 14px;
    left: 1em;
  }

  .responsive-table tr {
    border-bottom: 1px solid #195190;
  }

  .course-text-container {
    justify-content: center;
  }

  .course-cell {
    width: 100%;
    justify-items: center;
    align-items: center;
    font-weight: bold;
  }
  
  .ects-cell, .grade-cell {
    width: 100%;
  }

  td {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    position: relative;
  }

  td > input {
    width: 100%;
    padding: 1em 0em 1em 2em;
    text-align: center;
  }

  td > input:focus {
    border: none;
  }

  .course-text-container {
    max-width: 22em;
  }
  
  .text-course {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Hidden tr with 3 tds, to define column widths as percentages, stop taking space in small screns */
  thead > tr.hidden-top-tr {
    display: none;
    visibility: hidden;
  }

}

.highlighted-row-elective-track .course-input, .highlighted-row-elective-track,
.highlighted-row-track .course-input, .highlighted-row-track {
  background-color: #FF793B;
}

.highlighted-row-elective-track:hover td, .highlighted-row-elective-track:hover .course-input,
.highlighted-row-track:hover td, .highlighted-row-track:hover .course-input {
  background-color: #FFC9B1;
}

.highlighted-row-elective-spec .course-input, .highlighted-row-elective-spec,
.highlighted-row-elective-both-specs .course-input, .highlighted-row-elective-both-specs,
.highlighted-row-elective-extra-spec .course-input, .highlighted-row-elective-extra-spec,
.highlighted-row-spec .course-input, .highlighted-row-spec,
.highlighted-row-extra-spec .course-input, .highlighted-row-extra-spec,
.highlighted-row-both-specs .course-input, .highlighted-row-both-specs,
.highlighted-row-project .course-input, .highlighted-row-project {
  background-color: #FBF719;
}

.highlighted-row-elective-spec:hover td, .highlighted-row-elective-spec:hover .course-input,
.highlighted-row-elective-extra-spec:hover td, .highlighted-row-elective-extra-spec:hover .course-input,
.highlighted-row-elective-both-specs:hover td, .highlighted-row-elective-both-specs:hover .course-input,
.highlighted-row-spec:hover td, .highlighted-row-spec:hover .course-input,
.highlighted-row-extra-spec:hover td, .highlighted-row-extra-spec:hover .course-input,
.highlighted-row-both-specs:hover td, .highlighted-row-both-specs:hover .course-input,
.highlighted-row-project:hover td, .highlighted-row-project:hover .course-input {
  background-color: #FDFCA3;
}

.info-tip {
  position: relative;
}

.info-tip-span {
  font-size: 9px;
  color: black;
  position: absolute;
  top: 1px;
  left: 1em;
}