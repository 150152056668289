select {
  padding: .5em;
  border: 1px solid #ccc;
  color: #195190;
  cursor: pointer;
}

select:focus {
  font-weight: 100;
}

.student-info-form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-bottom: 1em;
  text-transform: capitalize;
}

/* Correctly align switch label */
.last-item {
  margin-bottom: 1em;
}

.grid-item {
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-left: 1em;
}

label.grid-item {
  white-space: nowrap;
}

.grid-right {
  align-self: flex-end;
  justify-self: flex-start;
  margin-left: 2em;
  text-shadow: 0px 0px 1px;
}

select.grid-item, .switch {
  margin-right: 5em;
}

.passedCoursesInfo {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  padding-bottom: 1em;
  border-top: 2px solid #195190;
  transition: .5s visibility ease-in-out, opacity 1s ease-in-out;
  height: auto;
}

.no-display {
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: 0s visibility ease-in-out, opacity 0s ease-in-out;
}

.switch {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 32px;
  align-self: flex-end;
  justify-self: center;
  margin-top: 1em;
  margin-bottom: 1em
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 5px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #195190;
}

input:focus + .slider {
  box-shadow: 0 0 1px #195190;
}

input:checked + .slider:before {
  -webkit-transform: translateX(41px);
  -ms-transform: translateX(41px);
  transform: translateX(41px);
}

.slider.round {
  border-radius: 18px;
}

.slider.round:before {
  border-radius: 50%;
}

.grid-right {
  text-transform: lowercase;
}

@media screen and (max-width: 620px) {
  .student-info-form {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
  }

  .grid-item {
    display: flex;
    margin-top: 0em;
  }

  select.grid-item, .switch {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  
  .grid-right {
    align-self: flex-start;
    justify-self: flex-start;
    margin-left: 1em;
    margin-bottom: 1em;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 32px;
    align-self: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Correctly align switch label */
  .last-item {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 480px) {
  .passedCoursesInfo {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
  }


}

/* Two-columns display for passed courses info, User Info spread display in wider screens */
@media screen and (min-width: 620px) {
  .passedCoursesInfo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .passedCoursesInfo > .grid-item {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}