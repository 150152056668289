.privacy-policy {
  /* margin: 1em 2em; */
  margin: auto;
  height: 100%;
  max-width: 700px;
}

.privacy-policy > p {
  text-align: left;
  margin: 1em 2em;
}

.privacy-policy > h3 {
  text-align: left;
  margin-left: 1.7em;
}

.privacy-policy a {
  font-weight: bold;
  color: #195190;
  text-decoration: none;
}

.privacy-policy a:hover {
  color: #111;
}

.clear {
  clear: both;
  height: 3rem;
}

@media screen and (max-width: 480px) {
  .clear {
    height: 6rem;
  }  
}