form.search-form, .student-info-form {
  width: 90%;
  max-width: 800px;
  margin: auto;
  border: 2px solid #195190;
  transition: box-shadow 0.4s ease-in-out;
  transition: all .5s ease-in-out;
  background-color: #fff;
  display: flex;
}

.seach-icon {
  margin-left: .5em;
  transition: all .5s ease-in-out;
}

form.search-form > input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 1em;
  box-sizing: border-box;
  color: #195190;
  padding-left: .5em;
  font-size: 90%;
}

form.search-form:hover, .student-info-form:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.search-form:hover img {
  transform: scale(1.05);
}

.search-form:hover input {
  font-weight: bold;
}

form.search-form :focus {
  font-weight: bold;
  outline: none;
}

@media screen and (max-width: 480px) {
  form.search-form {
    margin-bottom: 1rem;
  }
}