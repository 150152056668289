.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #195190;
  color: #c7d3d4;
  font-size: 14px;
  position: absolute;
  bottom:0;
  left:0;
  width: 100%;
  text-align: center;
}

.footer p {
  margin: 1em;
}

.footer a {
  color: #c7d3d4;
  text-decoration: none;
}

.footer a:hover {
  color: #fcf6f5;
}

@media screen and (max-width: 480px) {

  .footer {
    display: grid;
    padding-bottom: 1em;
    justify-content: center;
    align-content: flex-start;
  }

  .footer p {
    grid-column: 1 / 3;
    margin-bottom: 0;
  }

}
