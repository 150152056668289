.navbar {
  background-color: #195190;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: #c7d3d4;
  align-items: center;
  margin-left: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  transition: all 0.5s ease;
}

.nav-logo:hover {
  color: #fcf6f5;
  transition: all 0.5s ease;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 1rem;
  margin-left: auto;
}

.nav-links {
  color: #c7d3d4;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}


.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #fcf6f5;
}

.nav-item :hover {
  color: #fcf6f5;
}

.nav-item .active {
  color: #fcf6f5;
  border-bottom: 3px solid #fcf6f5;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 720px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #195190;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #fcf6f5;
    border: none;
  }

  .nav-links {
    padding: .5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0.5rem;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fcf6f5;
  }

  .burger-icon {
    transition: all 0.3s ease-in-out;
  }
  
  .burger-icon.fade-in {
    transform: rotate(360deg);  
  }
}

.logo {
  width: 2.2rem;
  margin-top: .5em;
  margin-left: .5em;
  /* margin: 0; */
  position: absolute;
  top: 10%;
  /* border: solid 2px black; */
}
