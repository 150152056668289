.prompt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prompt-modal-content {
  background-color: #fcf6f5;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.x-button {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #195190;
  cursor: pointer;
  background-color: #fcf6f5;
  border: none;
}

.x-button:hover {
  opacity: .8;
}

.x-button:active {
  transform: scale(0.99);
  color: #123a68;
}

.prompt-modal-header {
  background-color: #fcf6f5;
  color: #195190;
  padding: 2em 3em;  
}


.form-group {
  display: grid;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* width: 100%; */
  margin-bottom: 10px;
}



.prompt-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin: 0 0 1em 0;
}

.prompt-modal-buttons button {
  padding: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.prompt-modal-buttons button:first-of-type {
  background-color: #fcf6f5;
  border: 1px solid #195190;
  width: 7em;
}

.prompt-modal-buttons button:last-of-type {
  background-color: #195190;
  color: #fff;
  width: 7em;
}

.prompt-modal-buttons button:hover {
  opacity: .9;
}

.prompt-modal-buttons button:first-of-type:active {
  transform: scale(0.99);
  background-color: #c7d3d4;
}

.prompt-modal-buttons button:last-of-type:active {
transform: scale(0.99);
background-color: #123a68;
}
